import { signal, useComputed, useSignal, useSignalEffect } from "@preact/signals"
import { Ref, useEffect, useRef } from "preact/hooks"
import { JSX } from "preact/jsx-runtime"
import { changeSignalObj } from "./utils.ts"
import { removeHidden, saveState, toHidden, toTopZ, winRegister, winUnregister } from "./WindowManager.tsx";

// import { phosphor } from "../imports.ts"

export interface WindowGeo {
  // pos except pos.z are in pixels when in use, size in rem
  // when defining, pos is vw,vh and size in rem
  pos: {x: number, y: number, z: number},
  size: {x: number, y: number}
}

const geoToPx = (src: WindowGeo): WindowGeo => {
  const v = {...src}
  
  const ww = window.innerWidth
  const wh = window.innerHeight
  const toPx = (val: number, winval: number) => (winval / 100) * val

  const remToPx = (val: number) => {
    const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
    return val * baseFontSize
  }

  v.pos.x = toPx(v.pos.x, ww) - remToPx(v.size.x) / 2
  v.pos.y = toPx(v.pos.y, wh) - remToPx(v.size.y) / 2

  return v
}

export type WindowState = 'normal' | 'hidden' | 'maximized'

export interface WindowData {
  title: string,
  geometry: WindowGeo,
  state: WindowState,
  lastState: WindowState,
}

export interface WindowProps {
  id: string,
  defTitle: string,
  geometry: WindowGeo,
  btCls?: boolean,
  btMin?: boolean,
  btMax?: boolean,
  start?: 'show' | 'hidden',
  children?: JSX.Element | string
  icon: string | [string, 'img' | 'iicon']
  moreClass?: string
  moreStyle?: string
}

export interface WIconProps {
  icon: string
  type: 'string' | 'img' | 'iicon'
  color?: string
}

export const WIcon = (props: WIconProps) => {
  switch (props.type) {
  case 'string': return <span class="wicon" style={props.color ? "color: " + props.color : ""}>{props.icon}</span>
  case 'iicon': return <i class={"wicon " + props.icon}  style={props.color ? "color: " + props.color : ""} />
  case 'img': return <img class="wicon" src={props.icon} alt="" />
  }
}

export const Window = (props: WindowProps) => {

  const self = useRef() as Ref<HTMLDialogElement>

  const data = useSignal<WindowData>({
    title: props.defTitle,
    state: props.start ? (props.start === 'show' ? 'normal' : 'hidden') : 'hidden',
    lastState: 'normal',
    geometry: props.geometry,
  })

  const setData = changeSignalObj(data)

  const active = useSignal(false)
  const mouse = useSignal({x:0, y: 0})
  const setMouse = changeSignalObj(mouse)

  const wi: WIconProps = {icon: '', type: 'string'}
  if (typeof(props.icon) === 'string') wi.icon = props.icon
  else if (props.icon.length === 2) {
    wi.icon = props.icon[0]
    wi.type = props.icon[1]
  } else throw new Error("invalid icon properties")

  useEffect(() => {
    setData('geometry', g => geoToPx(g))
    if (props.start === null) {
      close()
    } else switch (props.start) {
    case 'show': break
    case 'hidden': hide()
    }

    const moveHandler = (e:MouseEvent) => {
      if (!active.value) return
      if (data.value.state !== 'normal') return
      if (self.current === null) return
      const rec = self.current.getBoundingClientRect()

      const mx = e.clientX
      const my = e.clientY

      let dx = mouse.value.x - mx
      let dy = mouse.value.y - my

      setMouse('x', () => mx)
      setMouse('y', () => my)

      if(isNaN(dx)){dx=0}; if(isNaN(dy)){dy=0}

      let nx = rec.x - dx
      let ny = rec.y - dy
      const wwdt = rec.width
      const docel = document.documentElement

      if (ny < 0 || ny > docel.clientHeight - 32) ny = rec.y
      if (nx + (wwdt/2) < 0 || nx + (wwdt/2) > docel.clientWidth) nx = rec.x

      setData('geometry', geo => {
        const v: WindowGeo = {...geo}
        v.pos.x = nx
        v.pos.y = ny
        return v
      })
    }
    const upHandler = () => {
      active.value = false
      saveState()
    }

    // deno-lint-ignore no-window-prefix
    window.addEventListener('mousemove', moveHandler)
    // deno-lint-ignore no-window-prefix
    window.addEventListener('mouseup', upHandler)

    return () => {
      // deno-lint-ignore no-window-prefix
      window.removeEventListener('mousemove', moveHandler)
      // deno-lint-ignore no-window-prefix
      window.removeEventListener('mouseup', upHandler)
    }
  }, [])

  const setz = (idx: number) => {
    setData('geometry', geo => {
        const v: WindowGeo = {...geo}
        v.pos.z = idx
        return v
    })
  }

  const max = () => {
    self.current?.classList.add("max")
    setData('state', () => 'maximized')
    setData('lastState', () => 'maximized')
    saveState()
  }
  const unmax = () => {
    setData('state', () => 'normal')
    setData('lastState', () => 'normal')
    setTimeout(() => {
      self.current?.classList.remove("max")
    }, 500)
    saveState()
  }

  const maxBtn = () => {
    if (data.value.state === 'normal') max()
    else unmax()
  }

  const mouseDown = (e: JSX.TargetedMouseEvent<HTMLDivElement>) => {
    setMouse('x', () => e.clientX)
    setMouse('y', () => e.clientY)
    active.value = true
    toTopZ(props.id)
  }

  const hide = () => {
    setData('state', () => 'hidden')
    data.value = {...data.value}
    toHidden(props.id)
    saveState()
  }
  const show = () => {
    setData('state', () => data.value.lastState)
    data.value = {...data.value}
    removeHidden(props.id)
    toTopZ(props.id)
    saveState()
  }

  const close = () => {
    setData('state', () => 'hidden')
    data.value = {...data.value}
    saveState()
  }

  useEffect(() => {

    winRegister(props.id, {
      icon: wi,
      close: close,
      hide: hide,
      show: show,
      max: max,
      unmax: unmax,
      setz: setz,
      getWData: () => data.value,
      setWData: (d:WindowData) => {
        if (data.value === undefined) return
        data.value = d
      },
    })
    return () => {
      winUnregister(props.id)
    }
  }, [])

  const width = useComputed(() => data.value.state === 'normal' ?
    `${data.value.geometry.size.x}rem` : '100vw')
  const height = useComputed(() => data.value.state === 'normal' ?
    `${data.value.geometry.size.y}rem` : '100vh')
  
  const top = useComputed(() => data.value.state === 'normal' ?
    `${data.value.geometry.pos.y}px` : '0px')
  const left = useComputed(() => data.value.state === 'normal' ?
    `${data.value.geometry.pos.x}px` : '0px')

  const index = useComputed(() => data.value.geometry.pos.z)

  const opened = useComputed(() => !(data.value.state === 'hidden'))

  return <dialog open={opened.value} class={"window" + " " + props.moreClass} ref={self} style={{
    width: width.value,
    height: height.value,

    top: top.value,
    left: left.value,

    zIndex: index.value,
    // visibility: shown.value,

    '--win-wdt': width.value,
  }}>
    <header class="win-bar" onMouseDown={mouseDown}>
      <div class="line"></div>
      <div class="win-bar-head">
        <div class="left">
          <WIcon icon={wi.icon} type={wi.type} />
        </div>
        <span class="title">{data.value.title}</span>
        <div class="right win-buttons">
          {!props.btMin?<></>:<button aria-label="minimize window"
            onClick={hide}>
              <i class="ph-bold ph-minus" />
          </button>}
          {!props.btMax?<></>:<button aria-label="maximize window"
            onClick={maxBtn}>
              <i class="ph-bold ph-square" />
          </button>}
          {!props.btCls?<></>:<button aria-label="close window"
            onClick={close}>
              <i class="ph-bold ph-x" />
          </button>}
        </div>
      </div>
    </header>
    <div class="content">
      {props.children}
    </div>
  </dialog>
}