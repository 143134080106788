import { signal } from "@preact/signals";
import { changeSignalObj } from "./utils.ts";
import { JSX } from "preact/jsx-runtime";
import { WIcon, WIconProps, WindowData } from "./Window.tsx";
import { useEffect } from "preact/hooks";

export interface WindowFuncs {
  icon: WIconProps
  close: () => void,
  hide: () => void,
  show: () => void,
  max: () => void,
  unmax: () => void,
  setz: (idx:number) => void,
  getWData: () => WindowData,
  setWData: (data: WindowData) => void
}

export interface WindowEntry {
  [key: string]: WindowFuncs
}

interface WindowSaveData {
  [key: string]: WindowData
}

export interface WMState {
  windows: WindowEntry
  order: string[]
  hidden: string[]
}

const wmState = signal<WMState>({
  windows: {},
  order: [],
  hidden: [],
})
const setState = changeSignalObj(wmState)

const wmStateStore = "wmStateStore"
const wDataStore = "wDataStore"
const loaded = signal(false)

export const saveState = (force = false) => {
  if (!loaded.value && !force) return

  const windows = wmState.value.windows
  const wSData: WindowSaveData = {}
  for (const w in windows) {
    wSData[w] = windows[w].getWData()
  }
  const wD_j = JSON.stringify(wSData)
  // console.log(wSD_j)
  const wmS_j = JSON.stringify({...wmState.value, windows: {}} as WMState)
  // console.log(wmS_j)

  localStorage.setItem(wmStateStore, wmS_j)
  localStorage.setItem(wDataStore, wD_j)
}
export const loadState = () => {
  loaded.value = true
  const wmS_j = localStorage.getItem(wmStateStore)
  const wD_j = localStorage.getItem(wDataStore)
  if (wmS_j === null || wD_j === null) return
  const wmS: WMState = JSON.parse(wmS_j)
  const wD: WindowSaveData = JSON.parse(wD_j)
  
  wmState.value = {...wmS, windows: wmState.value.windows}
  const windows = wmState.value.windows
  for (const w in windows) {
    if (wD[w] === null) continue
    if (wD[w] === undefined) continue
    windows[w].setWData(wD[w])
  }
}
export const clearState = () => {
  localStorage.removeItem(wmStateStore)
  localStorage.removeItem(wDataStore)
}

const updateZ = () => {
  const state = wmState.value

  let cntr = 0 
  for (const w of state.order) {
    state.windows[w].setz(cntr)
    cntr += 1
  }
}

export const toTopZ = (id: string) => {
  const order = wmState.value.order
  if (!order.includes(id)) return

  const idx = order.indexOf(id)
  const newOrder = [...order.filter(i => i !== id), order[idx]]
  wmState.value.order = newOrder
  updateZ()
}

export const toHidden = (id: string) => {
  setState('hidden', h => {
    const v = [...h]
    v.push(id)
    return v
  })
}
export const removeHidden = (id: string) => {
  setState('hidden', h => {
    const v = [...h].filter(i => i !== id)
    return v
  })
}

export const winRegister = (id: string, funcs: WindowFuncs) => {
  const windows = wmState.value.windows
  if (windows[id] !== undefined) {
    console.log(`window with id '${id}' already registered`)
    return
  }
  const order = wmState.value.order

  windows[id] = funcs
  order.push(id)
  updateZ()
}

export const winUnregister = (id: string) => {
  const windows = wmState.value.windows
  if (windows[id] === undefined) return
  const order = wmState.value.order

  delete windows[id]
  wmState.value.order = order.filter(i => i !== id)
  updateZ()
}

const openWindow = (id: string) => {
  if (wmState.value.windows[id] === null) return
  wmState.value.windows[id].show()
}

const hideWindow = (id: string) => {
  if (wmState.value.windows[id] === null) return
  wmState.value.windows[id].hide()
}

export const WindowManager = () => {
  useEffect(() => {
    setTimeout(() => {
      loadState()
      wmState.value = {...wmState.value}
    }, 100)
  }, [])

  return <></>
}

export const HiddenList = () => <section class="hidden-list">
  {wmState.value.hidden.length < 1 ? <></> : <div class="line"></div> }
  {wmState.value.hidden.map(wid =>
    <button class="show-window" onClick={() => wmState.value.windows[wid].show()}>
      <WIcon icon={wmState.value.windows[wid].icon.icon} type={wmState.value.windows[wid].icon.type} />
      <span>{wmState.value.windows[wid].getWData().title}</span>
    </button>
  )}
</section>

type BtnChild = JSX.Element | string
interface WMBtnProps {
  id: string
  children: BtnChild
}

export const OpenWindow = (props: WMBtnProps) =>
  <button class="open-window" onClick={() => openWindow(props.id)}>{props.children}</button>
export const HideWindow = (props: WMBtnProps) =>
  <button class="hide-window" onClick={() => hideWindow(props.id)}>{props.children}</button>

interface OpenerProps {
  id: string
  children: BtnChild
  hide?: boolean
}

export const Opener = (props: OpenerProps) => {
  const id = props.id
  if (wmState.value.windows[id] === null) { 
    // console.error("couldn't find window with id " + props.id)
    return <></>
  }
  const win = wmState.value.windows[id]
  if (win === undefined) {
    // console.error("couldn't find window with id " + props.id)
    return <></>
  }
  if (props.hide && win.getWData().state !== 'hidden' || wmState.value.hidden.includes(id)) return <></>
  return <button class="opener" onClick={() => openWindow(id)}>{props.children}</button>
}

export const WSaveBtn = () => <button onClick={() => saveState(true)}>save state</button>
export const WLoadBtn = () => <button onClick={loadState}>load state</button>
export const WClrBtn = () => <button onClick={clearState}>clear state</button>